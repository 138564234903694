import * as THREE from 'three';

export class PlaneGeometry {
  constructor(classObj) {
    this.classObj = classObj;
    this.planeGeo = new THREE.PlaneGeometry( 20, 20, 32 );
    const tgaTextureDifuze = this.classObj.TGAloader.load('images/stone.tga');
    this.material = new THREE.MeshPhysicalMaterial( {
      color: 'gray',
      reflectivity: 1,
      // map: tgaTextureDifuze,
      // specularMap: tgaTextureDifuze,
      side: THREE.DoubleSide,
      // bumpMap: tgaTextureDifuze,
      // bumpScale: 0.1
    } );
    this.plane = new THREE.Mesh( this.planeGeo, this.material );
    this.plane.name = `plane`;
    this.plane.receiveShadow = true;
    this.drawMesh();
  }
  drawMesh() {
    this.classObj.scene.add(this.plane);
  }
  rotateX(degrees) {
    this.plane.rotation.x = this.degresToRadian(degrees);
  }
  rotateY(degrees) {
    this.plane.rotation.y = this.degresToRadian(degrees);
  }
  rotateZ(degrees) {
    this.plane.rotation.z = this.degresToRadian(degrees);
  }
  moveX(units) {
    this.plane.position.x = units;
  }
  moveY(units) {
    this.plane.position.y = units;
  }
  moveZ(units) {
    this.plane.position.z = units;
  }
  degresToRadian(degrees) {
    return degrees * Math.PI / 180;
  }
}