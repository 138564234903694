import * as THREE from 'three';

// export class SpotLight {
//   constructor(classObj, coordinates, lightID) {
//     this.classObj = classObj;
//     this.classObj.light = new THREE.SpotLight( '#f9d71c' );
//     this.classObj.light.angle = Math.PI / 6;
//     this.classObj.light.position.set( ...coordinates);
//     this.classObj.scene.add( this.classObj.light );

//     this.classObj.light.castShadow = true;
//     this.classObj.light.shadow.mapSize.width = 1024;
//     this.classObj.light.shadow.mapSize.height = 1024;
//     // this.classObj.light.shadow.camera.near = 0.1;
//     // this.classObj.light.shadow.camera.far = 2;
//   }
//   setPosition() {

//   }
// }

export class SpotLight {
  constructor(classObj, coordinates, lightID, castShadow) {
    this.classObj = classObj;
    this.classObj[`light_${lightID}`]= new THREE.SpotLight( '#ffffff' );
    this.classObj[`light_${lightID}`].angle = Math.PI / 6;
    this.classObj[`light_${lightID}`].position.set( ...coordinates);
    this.classObj.scene.add( this.classObj[`light_${lightID}`] );

    this.classObj[`light_${lightID}`].castShadow = castShadow;
    this.classObj[`light_${lightID}`].shadow.mapSize.width = 1024;
    this.classObj[`light_${lightID}`].shadow.mapSize.height = 1024;
    // this.classObj[`light_${lightID}`].shadow.camera.near = 0.1;
    // this.classObj[`light_${lightID}`].shadow.camera.far = 2;
    // this.classObj[`shadowCameraHelper_${lightID}`] = new THREE.CameraHelper( this.classObj[`light_${lightID}`].shadow.camera );
    // this.classObj.scene.add(this.classObj[`shadowCameraHelper_${lightID}`]) ;

    // this.classObj[`lightHelper_${lightID}`] = new THREE.SpotLightHelper( this.classObj[`light_${lightID}`] );
    // this.classObj.scene.add(this.classObj[`lightHelper_${lightID}`] );
  }
  setPosition() {

  }
}