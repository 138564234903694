import * as THREE from 'three';

export class CubeGeometry {
  constructor(classObj) {
    this.classObj = classObj;
    this.boxGeometry = new THREE.BoxGeometry( 3, 3, 3 );
    const tgaTextureDifuze = this.classObj.TGAloader.load('images/box_albedo.tga');
    this.texturePosition(tgaTextureDifuze);
    const tgaTextureNormal = this.classObj.TGAloader.load('images/box_normals.tga');
    this.texturePosition(tgaTextureNormal);
    const tgaTextureSpec = this.classObj.TGAloader.load('images/box_spek.tga');
    this.texturePosition(tgaTextureSpec);
    const tgaTextureBump = this.classObj.TGAloader.load('images/box_bumb.tga');
    this.texturePosition(tgaTextureSpec);
    this.boxMaterialPhongTGA = new THREE.MeshPhongMaterial({
      map: tgaTextureDifuze,
      normalMap: tgaTextureNormal,
      normalScale: new THREE.Vector2( 0.5, 0.5 ),
      specularMap: tgaTextureSpec,
      bumpMap: tgaTextureBump,
      bumpScale: 0.3
    });

    this.boxMaterial = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    // const difuze = new THREE.Color(0x00ff00);
    this.cube = new THREE.Mesh( this.boxGeometry, this.boxMaterialPhongTGA );
    this.cube.castShadow = true;
    this.drawMesh();
    this.moveOnTime();
  }
  texturePosition(texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set( 0.8, 0.82 );
    texture.offset.set(0.1, 0.01)
  }
  drawMesh() {
    // this.classObj.scene.add( this.cube );
  }
  moveX(units) {
    this.cube.position.x = units;
  }
  moveY(units) {
    this.cube.position.y = units;
  }
  moveZ(units) {
    this.cube.position.z = units;
  }
  moveOnTime(){
    // setInterval(()=> this.cube.position.x = this.cube.getWorldPosition().x + 0.1, 1000)
    // this.cube.getWorldPosition();
    console.log('this.cube.getWorldPosition();', this.cube.getWorldPosition().x);
  }
}