export class filterMethods {
  constructor(classObj) {
    this.classObj = classObj;
    this.mapOfDataBars = classObj.dataCubesMap
    this.filterMax = 100;
    this.filterMin = 0;
    this.daysInWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    this.hours = ['8', '10', '12', '14', '16', '18', '20', '22'];
  }

  filterbyValues(min = 0, max = 100) {
    this.filterMax = max;
    this.filterMin = min;
    this.changeVisibilityCondition();
  }
  daySelectFilter(){
    const inputElements = $('.dayCheckBox');
    const checkedValue = [];
    $(inputElements).each(function(index) {
      if(inputElements[index].checked){
        checkedValue.push(inputElements[index].value);
      }
    })
    this.daysInWeek = checkedValue;
    this.changeVisibilityCondition();
  }
  hourSelectFilter(){
    const inputElements = $('.hourCheckBox');
    const checkedValue = [];
    $(inputElements).each(function(index) {
      if(inputElements[index].checked){
        checkedValue.push(inputElements[index].value);
      }
    })
    this.hours = checkedValue;
    this.changeVisibilityCondition();
  }

  changeVisibilityCondition() {
    this.mapOfDataBars.forEach(keys=>{
      const valueOfBar = keys.getValueForBar()
      const conditionByValue = valueOfBar < this.filterMin || valueOfBar > this.filterMax;
      const conditionByDay = this.daysInWeek.indexOf(keys.getDayInWeek()) === -1 ;
      const conditionByHour = this.hours.indexOf(keys.getDayHour()) === -1 ;
      if (conditionByDay || conditionByValue || conditionByHour) {
        keys.changeVisibility(false);
      } else {
        keys.changeVisibility(true);
      }
    })
  }
}