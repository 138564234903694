export function prepareForm(classObj) {
  $('.dayCheckBox').change(event => {
    event.preventDefault();
    classObj.filterMethods.daySelectFilter();
  })
  $('.hourCheckBox').change(event => {
    event.preventDefault();
    classObj.filterMethods.hourSelectFilter();
  })
  $('.rotateScene').change(event => {
    event.preventDefault();
    classObj.rotateSceneSpeed = $('.rotateScene').prop('checked') ? 0.001 : 0;
  })
  $('.heightGrid').change(event => {
    event.preventDefault();
    classObj.gridGroups.visible = $('.heightGrid').prop('checked');
  })
  $('.adjustableGrid').change(event => {
    event.preventDefault();
    classObj.adjustableGrid.visible = $('.adjustableGrid').prop('checked');
  })
  $('.generateInterval').change(event => {
    event.preventDefault();
    const generateChecked = $('.generateInterval').prop('checked')
    if(generateChecked) {
      classObj.dataGenerator.startGenerateInterval();
    } else {
      classObj.dataGenerator.stopInterval();
    }
  })
}