import * as THREE from 'three';
import * as d3 from 'd3';

export class HeightGrid {
  constructor(classObj) {
    this.classObj = classObj;
    this.dataForChart = classObj.dataForChart;
    this.gridLineWeight = 0.02;
    this.gridGroups = this.classObj.gridGroups;
    this.adjustableGrid = this.classObj.adjustableGrid;
    this.valuesForGrid = [0, 20, 40, 60, 80, 100];
    this.drawGrid();
    this.adjustableGridDraw();
  }
  drawGrid() {
    this.valuesForGrid.forEach(gridHeight => {
      const gridGroup = new THREE.Group();
      this.classObj.dataForChart.forEach((dayInWeek ) => {
        this.dayLine(dayInWeek, gridGroup, '#08F2F6');
        dayInWeek.valuePerHour.forEach((timeOfDay) => {
          this.hourLine(timeOfDay, gridGroup, '#08F2F6');
        })
      })
      gridGroup.position.y = this.classObj.heighScale(gridHeight);
      this.gridGroups.add(gridGroup)
    })
    this.classObj.dataBarGroup.add(this.gridGroups);
  }

  adjustableGridDraw() {
    this.adjustableGrid.visible = false;
    this.classObj.dataForChart.forEach((dayInWeek ) => {
      this.dayLine(dayInWeek, this.adjustableGrid, 'red');
      dayInWeek.valuePerHour.forEach((timeOfDay) => {
        this.hourLine(timeOfDay, this.adjustableGrid, 'red');
      })
    })
    this.classObj.dataBarGroup.add(this.adjustableGrid);
  }

  setGridHeight(value) {
    this.adjustableGrid.position.y = this.classObj.heighScale(value);
  }

  hourLine(position, gridGroup, color) {
    this.boxGeometry = new THREE.BoxGeometry( 8, this.gridLineWeight, this.gridLineWeight );
    this.boxMaterialPhongTGA = new THREE.MeshPhongMaterial({
      color: color
    });
    this.cube = new THREE.Mesh( this.boxGeometry, this.boxMaterialPhongTGA );
    this.cube.name = `dataCube_${this.dayInWeek}_${this.timeOfDay}`;
    // this.cube.castShadow = true;
    this.cube.position.z = this.classObj.zScale(position.timeInDay);
    this.cube.position.x = 3.5;
    gridGroup.add(this.cube);
  }
  dayLine(position, gridGroup, color) {
    this.boxGeometry = new THREE.BoxGeometry( this.gridLineWeight, this.gridLineWeight, 12 );
    this.boxMaterialPhongTGA = new THREE.MeshPhongMaterial({
      color: color
    });
    this.cube = new THREE.Mesh( this.boxGeometry, this.boxMaterialPhongTGA );
    this.cube.name = `dataCube_${this.dayInWeek}_${this.timeOfDay}`;
    // this.cube.castShadow = true;
    this.cube.position.z = 5.5;
    this.cube.position.x = this.classObj.xScale(position.dayInWeek);
    gridGroup.add(this.cube);
  }
}