import * as d3 from 'd3';
const dayInWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
const timeInDay = ['8', '10', '12', '14', '16', '18', '20', '22'];
export const generateData = _ => {
  const outPutData = [];
  dayInWeek.forEach(dayInWeek => {
    const valuePerHour = [];
    timeInDay.forEach(timeInDay => {
      valuePerHour.push({
        timeInDay,
        value: Math.round((Math.random() * 100) * 100) /100
      })
    });
    outPutData.push({
      dayInWeek,
      valuePerHour
    })
  })
  return outPutData;
}

export const xScale = _ => {
  const scale = d3.scaleOrdinal()
    .domain(dayInWeek)
    .range(Array.from({length: dayInWeek.length}, (k, i) => i * 1.4))
  return scale;
}

export const zScale = _ => {
  const scale = d3.scaleOrdinal()
    .domain(timeInDay)
    .range(Array.from({length: timeInDay.length}, (k, i) => i * 1.4))
  return scale;
}

export const heighScale = _ => {
  const scale = d3.scaleLinear()
    .domain([0, 100])
    .range([0,4])
  return scale;
}

