import { textObject } from "./textObject";

export function setLabelDay(string, classObj) {
  classObj.labelAxe = new textObject(classObj, string.substring(0,3).toUpperCase(), 0.3);
  classObj.labelAxe.rotateText('x', -90);
  classObj.labelAxe.rotateText('z', -90);
  classObj.labelAxe.moveText('z', -1.5);
  classObj.labelAxe.moveText('x', classObj.xScale(string) + 0.2);
}

export function setLabelHour(string, classObj) {
  classObj.labelAxe = new textObject(classObj, string.substring(0,3), 0.5);
  classObj.labelAxe.rotateText('x', -90);
  classObj.labelAxe.rotateText('z', 180);
  classObj.labelAxe.moveText('z', -1.5);
  classObj.labelAxe.moveText('x', -0.2);
  classObj.labelAxe.moveText('z', classObj.zScale(string) + 0.2);
}

export function setLabelValue(classObj) {
  const arrayForScale = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  arrayForScale.forEach(dataForLabel => {
    classObj.labelAxe = new textObject(classObj, dataForLabel.toString(), 0.3);
    classObj.labelAxe.moveText('y', classObj.heighScale(dataForLabel) - 0.1);
    classObj.labelAxe.rotateText('y', 180);
  })
}

