import * as THREE from 'three';
import * as d3 from 'd3';

export class DataCubeGeometry {
  constructor(classObj, dayInWeek, timeOfDay) {
    this.classObj = classObj;
    this.dayInWeek = dayInWeek;
    this.timeOfDay = timeOfDay;
    this.colorScale = d3.scaleOrdinal()
      .domain([0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4])
      .range(['0xff0900', '0xff6200', '0xff9500', '0xffc800', '0xc3ff00',
       '0x80ff00', '0x00e1ff', '0x0095ff', '0x00ff1e']);
    this.scaleYtarget = 1;
    this.boxGeometry = new THREE.BoxGeometry( 0.5, 1, 0.5 );
    this.boxMaterialPhongTGA = new THREE.MeshPhongMaterial({
      color: '#08F2F6'
    });
    // this.boxMaterial = new THREE.MeshBasicMaterial({side: THREE.DoubleSide});
    this.cube = new THREE.Mesh( this.boxGeometry, this.boxMaterialPhongTGA );
    this.cube.name = `dataCube_${this.dayInWeek}_${this.timeOfDay}`;
    this.cube.castShadow = true;
    // this.difuzeColor = new THREE.Color(0x00ff00);
    // this.boxMaterial.color = "0x00ff00";
    this.drawMesh();
  }

  getValueForBar() {
    return this.realValue;
  }

  getDayInWeek() {
    return this.dayInWeek;
  }

  getDayHour() {
    return this.timeOfDay;
  }

  changeVisibility(visibilityFleg = true) {
    this.cube.visible = visibilityFleg;
  }

  drawMesh() {
    this.classObj.dataBarGroup.add(this.cube);
    // this.classObj.scene.add( this.cube );
  }
  moveX(units) {
    this.cube.position.x = units;
  }
  moveY(units) {
    this.cube.position.y = units;
  }
  moveZ(units) {
    this.cube.position.z = units;
  }
  scaleY(scaleUnit) {
    this.realValue = scaleUnit;
    this.scaleYtarget = this.classObj.heighScale(scaleUnit);
    this.cube.material.color.setHex(this.colorScale(Math.round(this.scaleYtarget*2)/2));
    this.runTransition()
  }

  runTransition() {
    let animationID = 0;
    if (this.cube.scale.y < 0) { this.cube.scale.y = 0 };
    let transition = () => {
      animationID = requestAnimationFrame( transition );
      if (this.cube.scale.y > this.scaleYtarget || this.cube.scale.y < this.scaleYtarget) {
        if(this.scaleYtarget < Math.abs(this.cube.scale.y)) {
          this.cube.scale.y -= 0.02;
          this.cube.position.y -= 0.02 / 2;
        }
        if (this.scaleYtarget > Math.abs(this.cube.scale.y)) {
          this.cube.scale.y += 0.02;
          this.cube.position.y += 0.02 / 2;
        }
        if (this.scaleYtarget === Math.floor(this.cube.scale.y)) {
          cancelAnimationFrame(animationID);
        }
      }
    }
    transition();
  }
}