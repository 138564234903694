import * as THREE from 'three';
import * as d3 from 'd3';

export class mouseEventRayCast {
  constructor(classObj) {
    this.classObj = classObj;
    this.tooltipContainer = $('.tooltip');
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.asignEventToContainer();
    this.scaleForValue = d3.scaleLinear()
    .domain([0, 4])
    .range([0, 100])
  }

  asignEventToContainer() {
    $('.container').on('mousemove', event => {
      this.canvasOvset = $('.container').offset();
      this.mouse.x = ((event.clientX - this.canvasOvset.left) / this.classObj.webGlWidth) * 2 - 1;
      this.mouse.y  = - (( event.clientY - this.canvasOvset.top) / this.classObj.webGlHeight) * 2 + 1;
      this.raycaster.setFromCamera( this.mouse, this.classObj.camera );
      const intersects = this.raycaster.intersectObjects(this.classObj.canvasGroup.children, true);
      if(intersects.length) {
        const keyOfSelectedCube = intersects[0].object.name.replace('dataCube_', '')
        if(this.classObj.dataCubesMap.has(keyOfSelectedCube)) {
          const selectedDataBar = this.classObj.dataCubesMap.get(keyOfSelectedCube);
          const scaleOfBar = selectedDataBar.cube.scale.y;
          // intersects[0].object.material.color.setHex('0x00ff1e');
          // this.createSphere(this.raycaster.ray(intersects[0].distance));
          this.tooltipContainer.css({top: event.clientY + 10, left: event.clientX + 10, display: 'block'})
          this.createTooltipContent(scaleOfBar, keyOfSelectedCube);
        } else {
          $('.tooltip').css({top: 0 , left: 0, display: 'none'})
        }
      } else {
        $('.tooltip').css({top: 0 , left: 0, display: 'none'})
      }
    })
  }
  createSphere(position) {
    const material = new THREE.MeshPhongMaterial({color: 'red', side: THREE.DoubleSide});
    const geometry = new THREE.SphereGeometry(0.1,16,16);
    const sphere = new THREE.Mesh(geometry, material)
    // sphere.position.set(position.x, position.y, position.z);
    sphere.position.set(position.x, 0, position.z);
    this.classObj.scene.add(sphere);
  }
  createTooltipContent(scaleOfBar, keyOfSelectedCubeName) {
    const GetDayAndTime = keyOfSelectedCubeName.split('_');
    const valueOfBar = this.scaleForValue(Math.round(scaleOfBar * 100) / 100);
    const header = $('<div>', {
      class: 'tooltip-header',
      html: `Value for ${GetDayAndTime[0]}, ${GetDayAndTime[1]}h`
    })
    const content = $('<div>', {
      class: 'tooltip-content',
      html: `${valueOfBar}`
    })
    this.tooltipContainer.html([header, content]);
  }
}