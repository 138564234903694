require('normalize.css/normalize.css');
require('./styles/index.scss');
import * as THREE from 'three';
import $ from "jquery";
import { CubeGeometry } from './objects/cubeObj';
import { PlaneGeometry } from './objects/planeObj';
import { OrbitControll } from './objects/orbitControll';
import { SpotLight } from './objects/lightSpot';
import TGALoader from 'threejs-ext/src/loaders/TGALoader';
import { generateData, xScale, zScale, heighScale } from './dataGenerator/generate-data';
import { ArrowHelper } from './objects/arrowObj';
import { filterMethods } from './objects/filterMethods';
import { DataGenerator } from './objects/intervalDataGenerate';
import { mouseEventRayCast } from './objects/mouseOver';
import { prepareForm } from './objects/formEvent';
import { HeightGrid } from './objects/heightGrid';
import { fontZaImport } from '../objects/font';

class ThreePlayground {
  constructor() {
    this.widthOfContainer = window.innerWidth < 1024 ? window.innerWidth * 0.8 : window.innerWidth * 0.5;
    this.widthOfControls = window.innerWidth < 1024 ? window.innerWidth * 0.7 : window.innerWidth * 0.3;
    this.contols = $('.controls');
    this.contols.width(this.widthOfControls);
    if(window.innerWidth < 1024) {this.contols.css({'margin-left': 0,'font-size': '12px'})}
    this.container = $('.container');
    this.container.width(this.widthOfContainer);
    this.webGlWidth = this.widthOfContainer;
    this.webGlHeight = this.webGlWidth * 1;
    this.rotateSceneSpeed = 0;
    this.dataForChart = generateData();
    this.xScale = xScale();
    this.zScale = zScale();
    this.heighScale = heighScale();
    this.fontCache = {};
    this.canvasGroup = new THREE.Group();
    this.dataBarGroup = new THREE.Group();
    this.gridGroups = new THREE.Group();
    this.adjustableGrid = new THREE.Group();
    this.dataGenerator = new DataGenerator(this);
    this.heightGrid = new HeightGrid(this);
    this.mouseRayCast = new mouseEventRayCast(this);
    this.fontCache = fontZaImport;

    this.myScene();
  }
  degresToRadian(degrees) {
    return degrees * Math.PI / 180;
  }
  myScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xf5f5f5);
    this.camera = new THREE.PerspectiveCamera( 75, this.webGlWidth/this.webGlHeight, 0.1, 1000 );
    this.camera.position.x = -4;
    this.camera.position.y = 8;
    this.camera.position.z = -10;
    this.TGAloader = new TGALoader();
    this.loader = new THREE.TextureLoader();

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize( this.webGlWidth, this.webGlHeight );
    $('.container').html(this.renderer.domElement );
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.renderer.gammaInput = true;
    this.renderer.gammaOutput = true;

    // const axesHelper = new THREE.AxesHelper( 50 );
    // this.scene.add(axesHelper);

    this.plane = new PlaneGeometry(this);
    this.plane.rotateX(90);
    this.plane.moveZ(2);
    // this.plane.moveY(this.heighScale(10));

    this.xAxe = new ArrowHelper(this, [1, 0, 0], 9);
    this.yAxe = new ArrowHelper(this, [0, 1, 0], 6);
    this.zAxe = new ArrowHelper(this, [0, 0, 1], 13);

    this.dataGenerator.generateDataBars();

    this.filterMethods = new filterMethods(this);
    prepareForm(this);

    new SpotLight(this, [-5, 25, -17 ], 1, false);
    new SpotLight(this, [-5, 25, -10 ], 2, false);
    new SpotLight(this, [15, 25, -10 ], 3, true);

    this.controll = new OrbitControll(this);
    this.canvasGroup.add(this.dataBarGroup);
    this.dataBarGroup.position.x = 0.5;
    this.dataBarGroup.position.z = 0.5;
    this.canvasGroup.position.x = -2;
    this.canvasGroup.position.z = -5;

    this.scene.add( this.canvasGroup );

    let radians = 0;
    var animate = () => {
      requestAnimationFrame( animate );
      radians += 0.01
      this.controll.update();
      this.scene.rotation.y += this.rotateSceneSpeed;
      this.renderer.render( this.scene, this.camera );
    };
    animate();
  }

  filterBars(min, max) {
    this.filterMethods.filterbyValues(min, max);
  }
}

$( document ).ready(function() {
  window.mythree = new ThreePlayground();
});
