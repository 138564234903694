import OrbitControls from 'three-orbitcontrols';

export class OrbitControll {
  constructor(classObj) {
    this.classObj = classObj;
    this.classObj.orbitControll =  new OrbitControls(this.classObj.camera,
      document.getElementById('container'));
    this.classObj.orbitControll.minPolarAngle = 0; // radians
    this.classObj.orbitControll.maxPolarAngle = Math.PI/2; // radians
    // this.classObj.orbitControll.minAzimuthAngle = - Math.PI/2; // radians
    // this.classObj.orbitControll.maxAzimuthAngle = Math.PI/2; // radians
    this.classObj.orbitControll.minDistance = 2;
    this.classObj.orbitControll.maxDistance = 200;
  }
  update() {
    this.classObj.orbitControll.update();
  }
}