import * as THREE from 'three';
import * as $ from 'jquery';
export class textObject {
  constructor(classObj, string, textSize = 0.6, color = 'black', extrudeHeight = 0.01) {
    this.classObj = classObj;
    this.string = string || 'Hello world';
    this.textSize = textSize;
    this.extrudeHeight = extrudeHeight;
    this.color = color;

    this.ajaxForFont();
    this.loading = true;
  }
  ajaxForFont() {
    $.ajax('objects/helvetiker_regular.typeface.json', {
      context: this,
      success: font => {
        // this.classObj.fontCache = font;
        this.makeGeometry();
        this.loading = false;
      }
    })
  }
  makeGeometry() {
    this.geometry = new THREE.TextGeometry( this.string, {
      font: new THREE.Font(this.classObj.fontCache),
      size: this.textSize,
      height: this.extrudeHeight,
      curveSegments: 10,
      // bevelEnabled: true,
      // bevelThickness: 1,
      // bevelSize: 1,
      // bevelSegments: 1
    } );
    this.textMaterial = new THREE.MeshBasicMaterial( { color: this.color} );
    this.textGeometry = new THREE.Mesh( this.geometry , this.textMaterial);
    this.textGeometry.name = `text_geo_${this.string}`
    this.addToScene();
  }
  addToScene() {
    this.classObj.canvasGroup.add(this.textGeometry);
  }
  rotateText(axe, degrees){
    this.intervalCircles = 0;
    let interval = setInterval(_ => {
      if (this.intervalCircles >= 12 || !this.loading) {clearInterval(interval);}
      if (!this.loading) {
        this.textGeometry.rotation[axe] = this.degresToRadian(degrees);
        clearInterval(interval)
      }
      this.intervalCircles += 1;
    }, 50)
  }
  moveText(axe, amount) {
    this.intervalCircles = 0;
    let interval = setInterval(_ => {
      if (this.intervalCircles >= 12 || !this.loading) {clearInterval(interval);}
      if (!this.loading) {
        this.textGeometry.position[axe] = amount;
        clearInterval(interval)
      }
      this.intervalCircles += 1;
    }, 50)
  }
  degresToRadian(degrees) {
    return degrees * Math.PI / 180;
  }
}