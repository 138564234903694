import { generateData } from '../dataGenerator/generate-data';
import { DataCubeGeometry } from './dataCube';
import { setLabelDay, setLabelHour, setLabelValue } from './labels';

export class DataGenerator {
  constructor(classObj) {
    this.classObj = classObj;
    this.interval;
  }
  generateDataBars() {
    this.classObj.dataCubesMap = new Map();
    this.classObj.dataForChart.forEach((dayInWeek ) => {
      setLabelDay(dayInWeek.dayInWeek, this.classObj);
      dayInWeek.valuePerHour.forEach((timeOfDay) => {
        setLabelHour(timeOfDay.timeInDay, this.classObj)
        const dataCube = new DataCubeGeometry(this.classObj, dayInWeek.dayInWeek, timeOfDay.timeInDay);
        dataCube.moveY(.5);
        dataCube.moveX(this.classObj.xScale(dayInWeek.dayInWeek));
        dataCube.moveZ(this.classObj.zScale(timeOfDay.timeInDay));
        dataCube.scaleY(timeOfDay.value);
        const keyForMap = `${dayInWeek.dayInWeek}_${timeOfDay.timeInDay}`;
        this.classObj.dataCubesMap.set(keyForMap, dataCube);
      })
    })
    setLabelValue(this.classObj);
  }

  startGenerateInterval() {
    this.interval = setInterval(_ => {
      this.classObj.dataForChart = generateData();
      this.classObj.dataForChart.forEach((dayInWeek )=> {
        dayInWeek.valuePerHour.forEach((timeOfDay) => {
          const keyForMap = `${dayInWeek.dayInWeek}_${timeOfDay.timeInDay}`;
          const dataCubeFromMap = this.classObj.dataCubesMap.get(keyForMap);
          dataCubeFromMap.scaleY(timeOfDay.value);
        })
      })
      this.classObj.filterMethods.changeVisibilityCondition();
    }, 2000)
  }
  stopInterval() {
    clearInterval(this.interval);
  }
}