import * as THREE from 'three';
export class ArrowHelper {
  constructor(classObj, directionVetor, length) {
    this.classObj = classObj;
    this.direction = new THREE.Vector3(...directionVetor);
    this.origin = new THREE.Vector3( 0, 0, 0 );
    this.length = length;
    this.hex = 0x000000;
    this.arrowHelper = new THREE.ArrowHelper( this.direction, this.origin, this.length, this.hex, 1, 0.3 );
    this.addToScene();
  }
  addToScene() {
    this.classObj.canvasGroup.add(this.arrowHelper);
    // this.classObj.scene.add(this.arrowHelper);
  }
}